import { ErrorTypesEnum } from '../../composables/error/types'

const defaultError = {
  title: 'Došlo k problému při zpracování požadavku.',
  description: `
    Omlouváme se, ale stránka, kterou hledáte, nebyla nalezena. Prosím, zkuste ověřit správnost URL adresy. 
    V případě přetrvávajících problémů nás kontaktujte na {contact}
  `,
}

export default {
  [ErrorTypesEnum.unknownError]: defaultError,
  [ErrorTypesEnum.issueNotFound]: defaultError,
  [ErrorTypesEnum.issueIdMissing]: defaultError,
  [ErrorTypesEnum.missingToken]: defaultError,
  [ErrorTypesEnum.wrongToken]: defaultError,

  [ErrorTypesEnum.documentUploadFailed]: {
    title: 'Došlo k problému při nahrávání souboru.',
    description: `
        Ujistěte se, že je soubor ve správném formátu (.PDF, .PNG, .JPG, .JPEG, .HEIF, nebo .HEIC) a zkuste ho nahrát znovu. 
        Pokud bude problém přetrvávat, pošlete nám prosím soubor v příloze e-mailu na adresu {email}. 
        Děkujeme a omlouváme se za komplikace.
    `,
  },
}
