export default {
  success: {
    title: 'Objednávku dluhopisu zpracováváme',
    description: `
        Během chvíle obdržíte připravenou smlouvu na svůj e&#8209;mail. 
        Smlouvu vystiskněte, podepište ji a zašlete nám zpět. V e&#8209;mailu vám zašleme také instrukce pro provedení platby za dluhopis.
    `,

    buttons: {
      primary: 'Prohlédnout si emisi na Srovnávači',
      secondary: 'Jak funguje nákup dluhopisu',
    },
  },

  error: {
    title: 'Něco se pokazilo',
    description: `
        Bohužel se nám nepodařilo zpracovat vaši objednávku.
        Zkuste to prosím znovu nebo nás kontaktujte na telefonním čísle <a href="tel:{phone}">{phone}</a>.
    `,
  },
}
