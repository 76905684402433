import { PersonTypesEnum } from '~/composables/investor/types'

export default {
  title: 'Osobní údaje',

  legalType: {
    [PersonTypesEnum.Person]: {
      label: 'Fyzická osoba',
    },

    [PersonTypesEnum.Company]: {
      label: 'Firma',
    },
  },

  form: {
    address: {
      empty: 'Nenalezena žádná adresa',
      placeholder: 'Adresa trvalého bydliště',
    },

    isMailingAddresIsSameAsAddress: {
      physicalPerson: 'Korespondenční adresa je stejná jako adresa trvalého bydliště',
      firm: 'Korespondenční adresa je stejná jako adresa sídla společnosti',
    },

    mailingAddress: {
      empty: 'Nenalezena žádná adresa',
      placeholder: 'Korespondenční adresa',
    },

    birthNumber: {
      placeholder: 'Rodné číslo',
      birthDatePlaceholder: 'Datum narození',
      birthNumberPlaceholder: 'Rodné číslo',
    },

    bankAccountNumber: {
      placeholder: 'Číslo účtu',
    },

    note: {
      placeholder: 'Poznámka pro emitenta',
    },

    companyName: {
      placeholder: 'Název společnosti',
    },

    companyId: {
      placeholder: 'IČO',
    },

    companyAddress: {
      empty: 'Nenalezena žádná adresa',
      placeholder: 'Adresa sídla',
    },

    representativeAddress: {
      empty: 'Nenalezena žádná adresa',
      placeholder: 'Adresa trvalého bydliště osoby zastupující společnost',
    },

    representingPersonName: {
      placeholder: 'Jméno osoby zastupující společnost',
    },

    submit: {
      label: 'Objednat',
    },

    additionalPaymentInfo: {
      title: 'Údaje pro výplaty výnosů a jistiny',
      label: 'Přidat detailní údaje pro výplaty',
      isSymbolDataSameAsPrincipal: {
        placeholder: 'Údaje pro výplatu jistiny jsou stejné jako pro výplatu výnosů',
      },

      variableSymbolInterest: {
        placeholder: 'Variabilní symbol pro výplatu úroku',
      },
      specificSymbolInterest: {
        placeholder: 'Specifický symbol pro výplatu úroku',
      },
      principalBankAccountNumber: {
        placeholder: 'Číslo účtu pro výplatu jistiny',
      },
      variableSymbolPrincipal: {
        placeholder: 'Variabilní symbol pro výplatu jistiny',
      },
      specificSymbolPrincipal: {
        placeholder: 'Specifický symbol pro výplatu jistiny',
      },
    },
  },
}
