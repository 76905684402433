export default {
  title: 'Podmínky užívání aplikace James',
  text: `
    <div>
        <h3 class="text-center mb-5 mt-8">
          I. ÚVODNÍ USTANOVENÍ
        </h3>

        <p>
          I.1. Aplikace James (dále jen jako „Aplikace“) je provozována společností
          CFG Tech a.s., IČO 065 04 591, sídlem Vinohradská 2396/184,
          Vinohrady, 130 00 Praha 3 (dále jen jako „Společnost“).
        </p>


        <p>
          I.2. Aplikace byla Společností vyvinuta za účelem administrace smluvních
          vztahů mezi emitenty a investory, kteří se rozhodnou investovat do
          dluhopisů vydaných těmito emitenty. Uživatelem Aplikace je tedy nejenom
          emitent, který nabízí v Aplikaci své dluhopisy, ale i investor, který má
          zájem tyto dluhopisy koupit.
        </p>

        <p>
          I.3. Aplikace je vytvořena v České republice a řídí se právními předpisy
          České republiky.
        </p>

        <p>
          I.4. Společnost upozorňuje všechny uživatele Aplikace, že Společnost
          neposkytuje investiční službu přijímání a předávání pokynů týkajících se
          investičních nástrojů, a to ani zprostředkování této investiční služby,
          investiční poradenství týkající se investičních nástrojů či umisťování
          investičních nástrojů bez závazku jejich upsání, ani žádnou jinou
          investiční službu ve smyslu zákona č. 256/2004 Sb., o podnikání na
          kapitálovém trhu a nezastává ani žádnou další roli dle tohoto zákona
          (burza cenných papírů, organizátor regulovaného trhu, provozovatel
          mnohostranného obchodního systému apod.).
        </p>

        <p>
          I.5. Společnost dále upozorňuje investory, že prostřednictvím Aplikace
          nakupuje investor dluhopisy přímo od konkrétního emitenta, o jehož
          dluhopisy projeví zájem. K uzavření smluvního vztahu, tj. k nákupu
          dluhopisů, dochází přímo mezi emitentem a investorem, a to bez jakéhokoli
          zapojení Společnosti. Společnost proto nepřebírá žádnou odpovědnost za
          závazky emitentů ani investorů, které pro ně ze smluvních vztahů
          administrovaných v Aplikaci vyplývají.
        </p>

        <p>
          I.6. Uveřejnění dluhopisů emitentů v Aplikaci nelze v žádném případě
          vykládat jako investiční doporučení ke koupi těchto dluhopisů, ani
          jakékoliv hodnocení finančního stavu emitentů, predikcí budoucího vývoje
          činnosti emitentů či hodnocení vhodnosti či výhodnosti investic pro
          konkrétní investory. Společnost upozorňuje, že takto nemůže být vykládáno
          ani jakékoliv informativní či reklamní sdělení Společnosti učiněné za
          účelem propagace Aplikace či Společnosti.
        </p>

        <p>
          I.7. Společnost doporučuje investorům, aby předem nákup dluhopisů vždy
          zvážili a aby si veškeré informace o emitentech a jejich dluhopisech
          důkladně ověřili, popř. aby si vyžádali radu u nezávislých odborných
          investičních, ekonomických či právních poradců.
        </p>

        <p>
          I.8. Každý investor do korporátních dluhopisů by si měl být před jejich
          nákupem vědom toho, že s touto investicí je spojeno riziko ztráty
          investice a taktéž riziko nedosažení zisku. Jakýkoliv historický výnos
          není zárukou výnosů budoucích, ty závisí především na budoucím vývoji
          emitentů a jejich podnikání.
        </p>

        <h3 class="text-center mb-5 mt-8">
          II. PODMÍNKY UŽÍVÁNÍ APLIKACE
        </h3>

        <p>
          II.1. Veškeré informace o emitentech a o jimi emitovaných dluhopisech v
          Aplikaci jsou uveřejněny z podnětu samotných emitentů, kteří nesou
          odpovědnost za jejich správnost, přesnost, úplnost a zákonnost.
        </p>

        <p>
          II.2. Společnost za informace o emitentech a o jimi emitovaných
          dluhopisech v Aplikaci neodpovídá a ani je neschvaluje a neupravuje. V
          případě, že Společnost, ať už z vlastní činnosti či na základě podnětu,
          zjistí, že emitent uveřejnil v Aplikace klamavé, nepravdivé, nepřesné nebo
          zavádějící informace, je Společnost oprávněna upozornit tohoto emitenta na
          tuto skutečnost. V případě, že emitent nezjedná nápravu ani v přiměřené
          době, je Společnost oprávněna okamžitě zablokovat tohoto emitenta a nadále
          mu neumožnit Aplikaci používat.
        </p>

        <p>
          II.3. Investoři jsou povinni při vyplnění objednávkového formuláře v
          Aplikaci uvádět jen přesné, pravdivé a úplné údaje o své osobě. Investor
          odesláním formuláře potvrzuje správnost a přesnost údajů uvedených ve
          formuláři a také to, že si je vědom následků vyplývajících z uvedení
          nepravdivých údajů.
        </p>

        <p>
          II.4. Společnost ani Aplikace není oprávněna ověřovat, upravovat či
          schvalovat údaje uvedené investory v Aplikaci ani jejich totožnost.
          Ověření totožnosti může být požadováno pouze ze strany konkrétního
          emitenta.
        </p>

        <p>
          II.5. Správci osobních údajů uváděných investory v Aplikaci jsou konkrétní
          emitenti, o jejichž dluhopisy projeví investoři zájem. Emitenti plní svou
          informační povinnost dle Nařízení Evropského parlamentu a Rady (EU)
          2016/679 ze dne 27. dubna 2016 o ochraně fyzických osob v souvislosti se
          zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení
          směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) a zákona
          č.110/2019 Sb., o zpracování osobních údajů, zveřejněním Zásad zpracování
          osobních údajů, které jsou dostupné v Aplikaci.
        </p>

        <p>
          II.6. Investoři tímto berou na vědomí, že emitenti jsou oprávněni po
          investorech požadovat doplnění jejich osobních údajů či poskytnutí
          součinnosti za účelem provedení identifikace a kontroly dle zákona č.
          253/2008 Sb., o některých opatřeních proti legalizaci výnosů z trestné
          činnosti a financování terorismu (dále jen jako „AML zákon“). Emitenti
          jsou v takovém případě povinni investory řádně poučit o jejich
          povinnostech vyplývajících z AML zákona.
        </p>

        <p>
          II.7. Investoři a emitenti s ohledem na AML zákon prohlašují, že
          prostřednictvím smluvních vztahů administrovaných v Aplikaci nedochází k
          legalizaci výnosů z trestné činnosti ani k financování terorismu.
        </p>

        <p>
          II.8. Odesláním vyplněného formuláře v Aplikaci investor dále prohlašuje,
          že je oprávněn vstupovat do smluvního vztahu s emitentem a že je oprávněn
          činit právní jednání. Dále také prohlašuje, že se seznámil se zněním všech
          relevantních dokumentů, které se k nakoupeným dluhopisům vztahují, a to
          především s emisními podmínkami těchto dluhopisů.
        </p>

        <p>
          II.9. Investoři berou na vědomí, že v souladu s ustanovením § 1103 odst. 2
          zákona č. 89/2012 Sb. občanského zákoníku, přechází vlastnické právo k
          dluhopisům až fyzickým předáním dluhopisů. Způsob předání dluhopisů
          stanovuje emitent.
        </p>

        <h3 class="text-center mb-5 mt-8">
          III. POSTUP PRO VYŘIZOVÁNÍ STÍŽNOSTÍ
        </h3>

        <p>
          III.1. Každý uživatel Aplikace je oprávněn se obrátit na Společnost se
          stížností ohledně fungování Aplikace či chování jiných jejích uživatelů.
          Stížnost je možné odeslat v elektronické formě na e-mailovou adresu
          {email}. Společnost je povinna stížnost přijmout a její
          přijetí odesilateli stížnosti potvrdit.
        </p>

        <p>
          III.2. Společnost se zavazuje vyřídit stížnost bez zbytečného odkladu. V
          případě, že Společnost zjistí pochybení, zavazuje se Společnost učinit
          nezbytná nápravná opatření.
        </p>

        <p>
          III.3. V případě, že Společnost zjistí pochybení na straně uživatele
          Aplikace, např. v tom, že daný uživatel porušuje buď tyto podmínky, právní
          předpisy či dobré mravy, je Společnost povinna vyzvat tohoto uživatele k
          nápravě. Neučiní-li tak tento uživatel ani v přiměřené lhůtě, je
          Společnost oprávněna tomuto uživateli okamžitě znemožnit používání
          Aplikace.
        </p>

        <p>
          III.4. O výsledku šetření stížnosti, tj. o přijatých nápravných opatřeních
          či o tom, že žádné pochybení nebylo zjištěno, je Společnost povinna
          informovat stěžovatele bez zbytečného odkladu.
        </p>

        <h3 class="text-center mb-5 mt-8">
          IV. VÝHRADY SPOLEČNOSTI
        </h3>

        <p>
          IV.1. Uživatelé používají Aplikaci na vlastní riziko. Společnost
          neodpovídá za žádnou přímou ani nepřímou škodu či újmu, včetně ztráty
          uložených dat, která vznikla v důsledku užití či nemožnosti užití
          Aplikace.
        </p>

        <p>
          IV.2. Společnost si vyhrazuje právo ukončit poskytování služeb v Aplikaci.
        </p>

        <p>
          IV.3. Společnost neodpovídá za chyby, které vznikly v důsledku zásahů
          třetích osob do Aplikace nebo v důsledku toho, že byla Aplikace užita v
          rozporu s jejím určením. Uživatelé nejsou oprávněni při užívání Aplikace
          užívat mechanismy, programové vybavení, skripty nebo jiné postupy, které
          by mohly mít negativní vliv na provoz Aplikace, tj. nesmí narušit její
          funkci, nepřiměřeně zatěžovat systém či užít Aplikaci způsobem, který je v
          rozporu s jejím účelem.
        </p>

        <p>
          IV.4. Společnost neručí za to, že přístup do Aplikace nebude přerušen ani
          za to, že Aplikace bude bez závad či bude zcela bezpečná. Společnost
          neodpovídá za škody způsobené přerušením provozu Aplikace, či její
          poruchou nebo počítačovými a jinými viry, dále také za škodu v důsledku
          ztráty dat, zisku nebo neoprávněného přístupu k přenosům a datům.
        </p>

        <h3 class="text-center mb-5 mt-8">
          V. OCHRANA SPOLEČNOSTI
        </h3>

        <p>
          V.1. Veškerý obsah Aplikace vztahující se ke Společnosti a k samotné
          Aplikaci (především loga, texty a tyto podmínky) včetně softwarového
          řešení samotné Aplikace je ve vlastnictví Společnosti a je tedy chráněn
          jejím autorským právem. Tento obsah nemůže být použit, měněn, kopírován
          nebo rozmnožován k jakémukoli účelu bez souhlasu Společnosti.
        </p>

        <p>
          V.2. V případě zásahu do autorského práva Společnosti je Společnost
          oprávněna se domáhat, aby bylo od těchto zásahů upuštěno a zároveň, aby jí
          byla nahrazena škoda tímto jednáním způsobená.
        </p>

        <h3 class="text-center mb-5 mt-8">
          VI. ZÁVĚREČNÁ USTANOVENÍ
        </h3>

        <p>
          VI.1. Tyto podmínky nabývají platnosti a účinnosti dnem jejich zveřejnění
          v Aplikaci.
        </p>

        <p>
          VI.2. Společnost je oprávněna znění těchto podmínek kdykoli změnit. O
          případných změnách těchto podmínek je Společnost povinna informovat
          uživatele Aplikace předem.
        </p>
    </div>
    `,
}
