export default {
  title: 'Objednávka dluhopisu {issueName}',
  referrers: {
    buffler: {
      description: 'Pokud máte jakékoliv nejasnosti nebo potřebujete pomoc, zavolejte přímo majiteli, rád s Vámi vše probere. Pan Radek Michalčík, ',
    },

    all: {
      description: `
        Pokud máte jakékoliv nejasnosti nebo potřebujete pomoc,
        rádi vám poskytneme podrobné informace na telefonním čísle
      `,
    },

    dmat: {
      description: `
        <b class="font-highlighted inline">Na objednávku společnosti {issueName}. jste přešli ze stránky dluhopisomat.cz.</b>
        <span>Pokud máte jakékoliv nejasnosti nebo potřebujete pomoc, rádi vám poskytneme podrobné informace na telefonním čísle</span>
      `,
    },

    srov: {
      description: `
        <b class="font-highlighted inline">Na objednávku společnosti {issueName}. jste přešli ze stránky srovnavacdluhopisu.cz.</b>
        <span>Pokud máte jakékoliv nejasnosti nebo potřebujete pomoc, rádi vám poskytneme podrobné informace na telefonním čísle</span>
      `,
    },
  },

  steps: {
    investment: {
      label: 'Vaše investice',
    },

    contacts: {
      label: 'Kontaktní údaje',
    },

    personalData: {
      label: 'Osobní údaje',
    },

    result: {
      label: 'Odesilání',
    },
  },

  stepsOf: 'Krok {current} z {total}',
}
