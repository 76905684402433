import common from '~/translations/cs/common'
import contacts from '~/translations/cs/contacts'
import summary from '~/translations/cs/summary'
import investor from '~/translations/cs/investor'
import result from '~/translations/cs/result'
import investment from '~/translations/cs/investment'
import documents from '~/translations/cs/documents'
import agreementUsage from '~/translations/cs/agreementUsage'
import agreementPersonalInfo from '~/translations/cs/agreementPersonalInfo'
import footer from '~/translations/cs/footer'
import error from '~/translations/cs/error'

export default {
  common,
  footer,
  summary,
  investment,
  contacts,
  investor,
  result,
  error,
  documents,
  agreementUsage,
  agreementPersonalInfo,
}
