/* tslint:disable */
/* eslint-disable */
/**
 * Bonds API
 * Microservice backend for bond management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const VariableInterestTypeEnum = {
    Repo2T: 'REPO_2T',
    Pribor1M: 'PRIBOR_1M',
    Pribor3M: 'PRIBOR_3M',
    Pribor6M: 'PRIBOR_6M',
    Pribor1R: 'PRIBOR_1R',
    Euribor1R: 'EURIBOR_1R',
    Inflation1M: 'INFLATION_1M',
    Inflation1R: 'INFLATION_1R'
} as const;
export type VariableInterestTypeEnum = typeof VariableInterestTypeEnum[keyof typeof VariableInterestTypeEnum];


export function VariableInterestTypeEnumFromJSON(json: any): VariableInterestTypeEnum {
    return VariableInterestTypeEnumFromJSONTyped(json, false);
}

export function VariableInterestTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableInterestTypeEnum {
    return json as VariableInterestTypeEnum;
}

export function VariableInterestTypeEnumToJSON(value?: VariableInterestTypeEnum | null): any {
    return value as any;
}

