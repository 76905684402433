import { VariableInterestTypeEnum } from '~/services/generated/client'

export default {
  title: 'Výše investice',
  bonus: 'Bonus ve výši {bonus} % p.a. vyplacený při každé výplatě (již započítán ve výnosu).',
  bonusOnce: '+ bonus ve výši {bonus} % investice vyplacený na konci emise!',
  calculator: {
    title: 'Kolik vyděláte',
    label: 'Kolik chcete investovat',

    parameters: {
      variable: 'Variabilní',
      variableYield: 'Variabilní výnos',

      maturityDate: {
        label: 'Datum splatnosti',
      },

      yearlyInterestRate: {
        label: 'Roční úrok',
        tooltip: `
          Znamená, jakým úrokem budou úročeny vklady investorů.
          Úrok = výnos pro investora (vždy p.a.).
        `,
        variableInterestTypes: {
          [VariableInterestTypeEnum.Repo2T]: '2T repo',
          [VariableInterestTypeEnum.Pribor1M]: '1M PRIBOR',
          [VariableInterestTypeEnum.Pribor3M]: '3M PRIBOR',
          [VariableInterestTypeEnum.Pribor6M]: '6M PRIBOR',
          [VariableInterestTypeEnum.Pribor1R]: '1R PRIBOR',
          [VariableInterestTypeEnum.Euribor1R]: '1R EURIBOR',
          [VariableInterestTypeEnum.Inflation1M]: '1M inflace',
          [VariableInterestTypeEnum.Inflation1R]: '1R inflace',
        },
      },
    },

    results: {
      periodic: {
        label: 'Roční výnos',
        tooltip: `
          Znamená Váš celkový čistý výnos z investice do dluhopisu za
          každý rok držení dluhopisu (po odečtení 15% daně z výnosu).
        `,
      },

      wholePeriod: {
        label: 'Čistý výnos',
        tooltip: 'Znamená Váš celkový čistý výnos z investice do dluhopisu po uplynutí stanovené doby (po zdanění 15% daně z výnosu). ',
      },
    },

    yearlyInterest: {
      label: 'Roční úrok',
      tooltip: `
        Znamená, jakým úrokem budou úročeny vklady investorů. Úrok =
        výnos pro investora (vždy p.a.).
     `,
    },
  },
  submit: {
    label: 'Pokračovat',
    title: 'Co bude dál?',
    caption: `
      Vyplníte osobní údaje potřebné pro přípravu smlouvy a odešlete objednávku.
      Během chvíle na váš e-mail dostanete smlouvu a údaje pro provedení platby.
      Podepíšete vytisknutou smlouvu a zašlete nám ji zpět.
    `,
  },

  tax: {
    title: 'Zdanění dluhopisů',
    description: 'Pokud je investorem <strong>fyzická osoba</strong> a jedná se o českého daňového rezidenta, <strong>odvádí daň z úrokových příjmů plynoucích z vlastnictví dluhopisů v podobě srážky 15 % z výše úrokového příjmu emitent</strong> a investorovi je vyplácen již čistý příjem.<br><br> Pokud je investorem <strong>právnická osoba</strong> a jedná se o českého daňového rezidenta, úrokové příjmy plynoucích z vlastnictví dluhopisů se stávají součástí <strong>obecného základu daně podléhajícího příslušné sazbě daně</strong> z příjmů právnických osob.<br><br> V ostatních případech platí zvláštní úprava.',
    link: 'Další informace o zdanění',
  },
}
