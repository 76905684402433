export default {
  title: 'Kontaktní údaje',

  form: {
    name: {
      placeholder: 'Jméno a příjmení',
    },

    phone: {
      placeholder: 'Telefon',
    },

    email: {
      placeholder: 'E-mail',
    },

    personalDataConsent: {
      error: 'Souhlas se zpracováním osobních údajů je povinný',

      placeholder: `
        <span class="prose font-parent block">
            Souhlasím se <a href="{url}">zpracováním osobních údajů</a>
        </span>
      `,
    },

    usageConditionsConsent: {
      error: 'Souhlas s podmínkámi užívání je povinný',
      placeholder: `
        <span class="prose block font-parent">
            Souhlasím s <a href="{url}">podmínkámi užívání </a>
        </span>
        `,
    },

    submit: {
      label: 'Pokračovat',
    },
  },
}
