import messages from './translations'

export default {
  legacy: false,
  locale: 'cs',
  messages,
  warnHtmlMessage: false,

  numberFormats: {
    cs: {
      currency: {
        style: 'currency',
        currency: 'CZK',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      currencyWithCents: {
        style: 'currency',
        currency: 'CZK',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        maximumFractionDigits: 2,
      },
    },
  },
}
