export default {
  title: 'Informace o zpracování osobních údajů',
  text: `
    <div>
        <p>
          Společnost {companyName}, IČO {companyRegNumber}, sídlem {companyAddress} (dále jen jako „Správce“) 
          tímto poskytuje informace o právech a povinnostech souvisejících se zpracováním osobních údajů v
          souvislosti s nákupem dluhopisů Správce. Správce je ten, kdo určuje účel a
          prostředky zpracování osobních údajů, provádí zpracování a odpovídá za
          něj. <b>Správce</b> při zpracování osobních údajů dodržuje všechny
          relevantní právní předpisy, především Nařízení Evropského parlamentu a
          Rady (EU) 2016/679 ze dne 27. dubna 2016 o ochraně fyzických osob v
          souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a
          o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) a
          zákon č.110/2019 Sb., o zpracování osobních údajů, a tímto plní svou
          informační povinnost zde stanovenou předložit přehledné a srozumitelné
          informace o tom, jak osobní údaje zpracovává.
        </p>
    
        <h3 class="text-center mb-5 mt-8">
          I. ZÁKLADNÍ POJMY
        </h3>
        <p>
          <b>Osobním údajem</b> je každá informace vztahující se k identifikované či
          identifikovatelné fyzické osobě (subjektu údajů).
        </p>
        <p>
          <b>Subjektem údajů</b> jsou investoři, fyzické osoby, kteří nakoupili
          dluhopisy Správce či projeví zájem o jejich nákup. V případě, že je
          investorem právnická osoba, shromažďuje Správce a zpracovává Správce
          osobní údaje zástupců tohoto investora.
        </p>
        <p>
          <b>Zpracováním</b> jsou jakékoliv operace nebo soubor operací, které jsou
          prováděny s osobními údaji nebo soubory osobních údajů pomocí či bez
          pomoci automatizovaných postupů, jako je shromáždění, zaznamenání,
          uspořádání, strukturování, uložení, přizpůsobení nebo pozměnění,
          vyhledání, nahlédnutí, použití, zpřístupnění přenosem, šíření nebo
          jakékoli jiné zpřístupnění, seřazení či zkombinování, omezení, výmaz nebo
          zničení.
        </p>
        <p>
          <b>Zpracovatelem</b> je ten, koho Správce pověřil, aby za něj prováděl s
          osobními údaji zpracovatelské operace.
        </p>
    
        <h3 class="text-center mb-5 mt-8">
          II. ROZSAH SHROMAŽĎOVANÝCH OSOBNÍCH ÚDAJŮ
        </h3>
        <p>
          Správce zpracovává pouze osobní údaje umožňující poskytnout řádně jeho
          služby, dodržovat právní povinnosti a chránit jeho oprávněné zájmy.
          Správce shromažďuje osobní údaje především o investorech, popř. o jejich
          zástupcích (dále jen jako <b>„Subjekty údajů“</b>).
        </p>
        <p>
          V případě, že Subjekt údajů projeví zájem o nákup dluhopisů Správce,
          uchovává Správce osobní údaje Subjektů údajů v následujícím rozsahu: jméno
          a příjmení, telefon, e-mailová adresa a údaje získané při návštěvě
          webových stránek. Tyto osobní údaje Správce zpracovává za účelem
          kontaktování Subjektů údajů a dokončení nákupu dluhopisů.
        </p>
        <p>
          Za účelem uzavření smlouvy o převodu dluhopisů shromažďuje Správce a
          zpracovává kromě výše uvedených osobních údajů další identifikační údaje
          Subjektu údajů, kterými jsou rodné číslo, místo narození, pohlaví, datum
          narození, adresa trvalého, případně jiného pobytu, číslo bankovního účtu,
          druh, číslo a platnost průkazu totožnosti a orgán, který ho vydal.
        </p>
    
        <h3 class="text-center mb-5 mt-8">
          III. ZDROJE OSOBNÍCH ÚDAJŮ
        </h3>
        <p>
          Osobní údaje Subjektů údajů získává Správce přímo od Subjektů údajů, a to
          prostřednictvím webového formuláře, který Subjekty údajů sami vyplní,
          popř. při telefonickém rozhovoru s ním v souvislosti s jednáním o uzavření
          smlouvy o převodu dluhopisů.
        </p>
    
        <h3 class="text-center mb-5 mt-8">
          IV. ÚČELY ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ A SOUHLAS S JEJICH ZPRACOVÁNÍM
        </h3>
        <p>
          Správce zpracovává osobní údaje Subjektů údajů za účelem uzavření smlouvy
          o převodu dluhopisů. K takovému zpracování nepotřebuje Správce souhlas
          Subjektů údajů. V případě, že Subjekty údajů tyto jejich osobní údaje
          Správci nesdělí, nebude možné smlouvu o převodu dluhopisů uzavřít.
        </p>
        <p>
          Bez souhlasu Subjektů údajů zpracovává Správce osobní údaje pro tyto
          účely:
        </p>
        <p class="ml-5">
          (i) <u>plnění povinností vyplývajících z právních předpisů</u>, především
          dle zákona č. 89/2012 Sb., občanský zákoník, zákona č. 563/1991 Sb., o
          účetnictví, zákona č. 499/2004 Sb., o archivnictví a spisové službě a
          zákona č. 253/2008 Sb., o některých opatřeních proti legalizaci výnosů z
          trestné činnosti a financování terorismu; a
        </p>
        <p class="ml-5">
          (ii) <u>ochrany práv a právem chráněných zájmů Správce</u>, například za
          účelem vymáhání pohledávek a uplatnění jiných nároků u soudů.
        </p>
        <p>
          Se souhlasem Subjektů údajů zpracovává Správce osobní údaje Subjektů údajů
          za účelem vytvoření a předání nabídky služeb a produktů Zpracovatelů,
          především CFG, tak jak je definováno níže, a společností s ním ve skupině
          a ostatních společností náležejících do skupiny Správce. Jestliže Subjekty
          údajů udělí souhlas s tímto předáním osobních údajů, mohou jim být
          zasílány zejména marketingové informace, a to e-mailem, adresovaným tiskem
          nebo prostřednictvím SMS.
        </p>
        <p>
          Souhlas se zpracováním osobních údajů je dobrovolný a Subjekty údajů jsou
          oprávněny jej kdykoliv odvolat. Odvolání souhlasu lze učinit v písemné
          formě doručením listinného odvolání na adresu Správce, uvedenou v záhlaví
          těchto informací, nebo v elektronické formě na e-mailovou adresu
          {issueEmail}.
        </p>
    
        <h3 class="text-center mb-5 mt-8">
          V. ZPŮSOB ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ A JEJICH OCHRANA
        </h3>
        <p>
          Správce zpracovává osobní údaje jak manuálně, tak v elektronických
          informačních systémech. Oba způsoby zpracování podléhají přísné fyzické,
          technické i procedurální kontrole. Osoby, které přicházejí do styku s
          osobními údaji, jsou řádně a pravidelně proškolovány a jsou vázány
          mlčenlivostí.
        </p>
        <p>
          Správce zpracovává osobní údaje Subjektů údajů především sám. Jejich
          zpracováním Správce dále pověřil společnost CFG Tech a.s., IČO
          065 04 591, sídlem Vinohradská 2396/184, Vinohrady, 130 00 Praha 3 (dále
          jen jako „<b>CFG</b>“).
        </p>
        <p>
          Každý zpracovatel je oprávněn nakládat s osobními údaji výhradně pro účely
          výkonu činnosti, ke které byl Správcem pověřen. K tomuto zpracování není
          potřeba souhlasu Subjektů údajů.
        </p>
    
        <h3 class="text-center mb-5 mt-8">
          VI. PŘEDÁVÁNÍ OSOBNÍCH ÚDAJŮ
        </h3>
        <p>
          Správce předává osobní údaje Subjektů údajů v souladu s právními předpisy
          a pokud je vyžadován, tak pouze v souladu se souhlasem Subjektů údajů.
          Správce nepředává osobní údaje do třetích zemí mimo EU.
        </p>
        <p>Správce osobní údaje Subjektů údajů předává následujícím subjektům:</p>
        <p class="ml-5">
          i.
          <u>subjektům v rámci plnění povinností vyplývajících z právních
            předpisů</u>, a to například soudům, orgánům činným v trestním řízení, správcům daně,
          soudním exekutorům, finančnímu arbitrovi, popř. jiným orgánům dohledu v
          rámci výkonu jejich zákonných pravomocí;
        </p>
        <p class="ml-5">
          ii.
          <u>dalším subjektům, pokud je to nezbytné pro ochranu práv a právem
            chráněných zájmů Správce</u>, a to např. soudům, exekutorům, dražebníkům apod., v rozsahu nezbytném
          pro uplatnění nároků Správce;
        </p>
        <p class="ml-5">
          iii.
          <u>osobám Správcem pověřeným k plnění smluvních a zákonných povinností</u>, včetně realizace práv ze smluvních vztahů, a to například účetním,
          poskytovatelům IT služeb, advokátům; a
        </p>
        <p class="ml-5">
          iv.
          <u>osobám, kterým lze na základě souhlasu Subjektů údajů osobní údaje
            předávat</u>, a to společnostem ve skupině Správce a Zpracovatelům, především CFG a
          společnostem s ním ve skupině.
        </p>
    
        <h3 class="text-center mb-5 mt-8">
          VII. DOBA ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ
        </h3>
        <p>
          Správce uchovává osobní údaje po celou dobu trvání smluvního vztahu se
          Subjekty údajů. Správce tyto osobní údaje uchovává i po jeho skončení, a
          to s ohledem na zákonnou povinnost dle zákona č. 563/1991 Sb., o
          účetnictví, a to dalších 10 let.
        </p>
        <p>
          Pokud Správce zpracovává osobní údaje výhradně pro účely ochrany jeho
          oprávněného zájmu, uchovává Správce tyto údaje po dobu trvání oprávněného
          zájmu. Osobní údaje zpracovávané na základě souhlasu Subjektů údajů,
          zpracovává Správce jen po dobu trvání tohoto souhlasu.
        </p>
    
        <h3 class="text-center mb-5 mt-8">
          VIII. PRÁVA V SOUVISLOSTI SE ZPRACOVÁNÍM OSOBNÍCH ÚDAJŮ
        </h3>
        <p>
          Subjekty údajů mají v oblasti ochrany osobních údajů řadu práv, která
          mohou vůči Správci uplatnit, a to tyto:
        </p>
        <p class="ml-5">
          <b>i. Právo na přístup k osobním údajům</b>. Subjekty údajů mají právo na
          přístup k jejich osobním údajům a dalším souvisejícím informacím (např.
          účel, kategorie osobních údajů, doba uchování, zdroj). Subjekty údajů mají
          taktéž právo požadovat kopii zpracovávaných osobních údajů. Za opakovanou
          žádost je Správce oprávněn požadovat úhradu nákladů spojených s
          poskytnutím této informace.
        </p>
        <p class="ml-5">
          <b>ii. Právo na přenositelnost údajů</b>. Subjekty údajů mají právo
          vyžadovat předání jejich osobních údajů, které Správci poskytly, a to za
          účelem jejich předání jinému správci kvůli usnadnění komunikace s tímto
          jiným Správcem. Subjekty údajů mohou toto právo uplatnit, jak pro osobní
          údaje, které Správce zpracovává na základě souhlasu Subjektů údajů, tak
          pro osobní údaje, které Správce zpracovává bez souhlasu.
        </p>
        <p class="ml-5">
          <b>iii. Právo nebýt předmětem automatizovaného individuálního rozhodování,
            včetně profilování</b>. Správce v současné době neprovádí automatizované rozhodování. Pokud se
          však tak v budoucnu rozhodne činit a Subjekty údajů nebudou chtít být
          předmětem automatizovaného rozhodování, včetně profilování, mohou Správce
          požádat, aby tak s ohledem na jejich osobu nečinil
        </p>
        <p class="ml-5">
          <b>iv. Právo na opravu osobních údajů</b>. Subjekty údajů mají právo žádat
          opravu jejich osobních údajů v případě, že jsou tyto osobní údaje
          nesprávné nebo nepřesné. S přihlédnutím k účelům, pro které Správce osobní
          údaje zpracovává, mají Subjekty údajů rovněž právo na jejich doplnění.
        </p>
        <p class="ml-5">
          <b>v. Právo na výmaz osobních údajů</b>. Subjekty údajů mají právo žádat
          vymazat osobní údaje a v případě, že Správce nemá pro jejich zpracování
          jiný právní titul než souhlas Subjektů údajů, je Správce povinen tyto
          osobní údaje na žádost Subjektů údajů vymazat. Správce vymazává osobní
          údaje průběžně, a to neprodleně poté, co k jejich zpracování ztratí právní
          titul.
        </p>
        <p class="ml-5">
          <b>vi. Právo na omezení zpracování osobních údajů</b>. Subjekty údajů mají
          právo na omezení zpracování jejich osobních údajů, a to zejména tehdy,
          pokud Správce bude vyřizovat námitku Subjektů údajů proti zpracování
          osobních údajů nebo upozornění na nepřesnost údajů.
        </p>
        <p class="ml-5">
          <b>vii. Právo na odvolání souhlasu se zpracováním osobních údajů</b>.
          Subjekty údajů mají právo kdykoli odvolat souhlas se zpracováním osobních
          údajů, který Správci udělily. Po odvolání souhlasu Správce ukončí
          zpracování osobních údajů, k jejichž zpracování nemá jiný právní titul než
          souhlas Subjektů údajů. Odvoláním souhlasu není dotčena zákonnost
          zpracování osobních údajů do okamžiku odvolání souhlasu.
        </p>
        <p class="ml-5">
          <b>viii. Právo podat námitku</b>. V případě, že si Subjekty údajů nepřejí,
          aby Správce pokračoval ve zpracování osobních údajů, které Správce provádí
          na základě ochrany jeho oprávněného zájmu, může Subjekt údajů uplatnit
          tzv. námitku. To je možné udělat prostřednictvím e-mailové zprávy odeslané
          na adresu: {issueEmail}.
        </p>
        <p>
          Námitka by měla být odůvodněna a mělo by z ní vyplývat, proč se Subjekty
          údajů domnívají, že zpracování osobních údajů nepříznivě zasahuje do
          jejich soukromí nebo jejich ochrany práv a právem chráněných zájmů. Tato
          námitka bude následně Správcem vyhodnocena, přičemž bude přihlížet k tomu,
          zda jeho oprávněný zájem na zpracování osobní údajů převyšuje dopad na
          Subjekty údajů spojené s tímto zpracováním.
        </p>
        <p>
          Jsou-li osobní údaje zpracovány pro účely přímého marketingu přestane
          Správce tyto osobní údaje zpracovávat po podání námitky ze strany Subjektů
          údajů bez dalšího.
        </p>
        <p class="ml-5">
          <b>ix. Právo podat stížnost ÚOOÚ</b>. Subjekty údajů mají právo obrátit se
          na Úřad pro ochranu osobních údajů, Pplk. Sochora 27, 170 00 Praha 7,
          www.uoou.cz se stížností ohledně zpracování osobních údajů Správcem.
        </p>
    </div>
    `,
}
