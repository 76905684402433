export default {
  currency: {
    CZK: 'Kč',
  },

  head: {
    index: {
      title: 'Objednávka dluhopisu',
    },

    contacts: {
      title: 'Kontaktní údaje',
    },

    investor: {
      title: 'Osobní údaje',
    },

    result: {
      title: 'Odeslání',
    },

    dokumenty: {
      title: 'Nahrajte dokumenty k Vaší objednávce',
    },
  },

  close: 'Zavřít',

  validators: {
    email: 'Zadejte platnou e-mailovou adresu',
    maximumDecimals: 'Zadejte číslo s max. {n} desetinnými místy',
    integer: 'Zadejte celé číslo',
    number: 'Zadejte číslo',
    positiveNumber: 'Zadejte kladné číslo',
    required: 'Pole je povinné',
    percentage: 'Zadejte číslo v rozsahu 0 až 100',
    ico: 'Zadejte platné IČO',
    bankAccount: {
      accountNumber: {
        invalid: 'Zadejte platný bankovní účet',
      },
      bankCode: 'Kod banky je povinný',
    },
    phone: 'Zadejte platné telefonní číslo',
    isin: 'Zadejte platný ISIN',
    birthNumber: 'Zadejte platné rodné číslo',
    variableSymbol: 'Zadejte platný variabilní symbol',
    specificSymbol: 'Zadejte platný specifický symbol',
  },

  bankAccount: {
    placeholder: 'Číslo účtu',
    code: 'Kód banky',
  },
}
