import { DocumentTypesEnum } from '~/composables/useDocuments/useDocuments'

export default {
  title: 'Nahrajte dokumenty k Vaší objednávce',
  caption: 'Níže prosím nahrajte vyplněné a podepsané dokumenty, které jsme Vám zaslali v příloze e-mailů.',

  types: {
    [DocumentTypesEnum.handoverDocument]: {
      name: 'Předávací protokol',
      description: 'Podepsaný předávací protokol ve formátu PDF nebo obrázku nahrajte zde',
    },
    [DocumentTypesEnum.orderForm]: {
      name: 'Objednávkový formulář',
      description: 'Vyplněný a podepsaný objednávkový formulář ve formátu PDF nebo obrázku nahrajte zde',
    },
  },

  upload: {
    label: 'Nahrát',
    success: {
      [DocumentTypesEnum.handoverDocument]: 'Podepsaný předávací protokol byl úspěšně nahrán.',
      [DocumentTypesEnum.orderForm]: 'Podepsaný objednávkový formulář byl úspěšně nahrán.',
      all: 'Podepsaný předávací protokol a objednávkový formulář byly úspěšně nahrány.',
    },
  },

  preview: {
    label: 'Zobrazit náhled',
  },

  uploadMore: {
    label: 'Nahrát jiný',
  },

  submit: {
    label: 'Odeslat',
  },
}
